




















































































import { SfFooter, SfList, SfImage, SfMenuItem, SfInput, SfIcon, SfLink } from '@storefront-ui/vue';
import { defineComponent, useRouter, useContext, ref, onMounted } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUiState } from '~/composables';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import useExtractFooterMenuItemsFromMagentoBlock from '~/composables/useCmsBlock/useExtractFooterMenuItemsFromMagentoBlock';

export default defineComponent({
  components: {
    SfFooter,
    SfList,
    SfImage,
    SfMenuItem,
    SfInput,
    SfIcon,
    SfLink,
    LoadWhenVisible,
    Manufacturer: () => import('~/components/Footer/Manufacturer.vue'),
    NewsletterForm: () => import('~/components/Footer/NewsletterForm.vue'),
    SocialMediaLinks: () => import('~/components/Footer/SocialMediaLinks.vue'),
    PaymentOptions: () => import('~/components/Footer/PaymentOptions.vue'),
    CopyRights: () => import('~/components/Footer/CopyRights.vue'),
    FooterColumn: () => import('~/components/Custom/Organisms/FooterColumn.vue'),
  },

  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleWishlistSidebar, toggleLoginModal } = useUiState();
    const { isAuthenticated } = useUser();
    const footerMenu = ref([]);
    const cmsBlocksStore = useCmsBlocksStore();

    onMounted(async () => {
      footerMenu.value = await getFooterMenuItems();
    });

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    async function getFooterMenuItems() {
      const blockData = cmsBlocksStore.blocks.cmsBlocks.find(block => block.identifier === "footer_links_block");
      const footerMenuItems = useExtractFooterMenuItemsFromMagentoBlock(blockData?.content);

      return footerMenuItems;
    }

    return {
      addBasePath,
      isAuthenticated,
      handleAccountClick,
      toggleWishlistSidebar,
      footerMenu
    };
  },
});
