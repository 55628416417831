import { parse } from 'node-html-parser';

export const useExtractFooterMenuItemsFromMagentoBlock = (menuItemsBlock) => {
    // Formatting to get clean html content
    menuItemsBlock = unEntity(menuItemsBlock);
    
    const root = parse(menuItemsBlock);
    const parents = root.querySelectorAll('ul');
    let menuItems = [];

    parents.forEach((parent, index) => {
        let parentMenu = {
            title: parent.getAttribute('title') ?? '',
            children: []
        };

        let children = parent.querySelectorAll('a');
        let childernMenuItems = [];
        children.forEach((child, index) => {
            let childMenu = {
                'title': decodeURIComponent(child.innerHTML) ?? '',
                'url': child.getAttribute('href') ?? '#',
            }
            childernMenuItems = [...childernMenuItems, childMenu];
        });
        parentMenu.children = childernMenuItems;
        menuItems = [...menuItems, parentMenu];
    });

    return menuItems;
};

function unEntity(str){
    return str.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
}

export default useExtractFooterMenuItemsFromMagentoBlock;
